<template>
  <v-card>
    <v-text-field
      v-model="email"
      label="E-mail"
      :rules="[required, emailRule]"
      variant="outlined"
    />
    <v-text-field
      v-model="email"
      label="Telefone"
      :rules="[required, emailRule]"
      variant="outlined"
    />
    <v-divider class="ma-4"></v-divider>

    <h2 class="ma-2">Endereço</h2>

    <v-text-field v-model="zip" label="CEP" variant="outlined" />
    <div v-if="resultado">
      <h2>Resultado:</h2>
      <p><strong>Logradouro:</strong> {{ resultado.logradouro }}</p>
      <p><strong>Bairro:</strong> {{ resultado.bairro }}</p>
      <p><strong>Cidade:</strong> {{ resultado.localidade }}</p>
      <p><strong>Estado:</strong> {{ resultado.uf }}</p>
    </div>

    <p v-if="erro" class="error">{{ erro }}</p>
    <v-text-field
      v-model="street"
      label="Rua"
      :rules="[required]"
      variant="outlined"
    />
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      required: (v) => !!v || "Este campo é obrigatório",
      zip: "",
      apiUrl: "https://geoapi.pt",
      resultado: null,
      erro: null,
    };
  },
  methods: {
    getData() {
      this.erro = null;
      this.resultado = null;
      axios.get(`${this.apiUrl}/cp/${this.zip}?json=1`).then(
        (response) => (this.localidade = response.data.Localidade)
        // (this.distrito = response.data.Distrito)
      );
    },
  },
  watch: {
    zip() {
      this.zip.length == 8 ? this.getData() : (this.zip = "");
    },
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
