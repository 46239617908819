<template>
  <div>
    <h2 class="text-center mb-6">Processo Simples e Transparente</h2>
    <p>
      Desde a sua aplicação até o embarque, garantimos que cada etapa seja clara
      e fácil de seguir. <br />Você terá suporte dedicado em cada momento.
    </p>
    <div class="landing-content-block">
      <v-img src="@/assets/Resume-amico.svg"> </v-img>

      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <template v-slot:prepend>
            <v-icon color="#f68c25">mdi-check-bold</v-icon>
          </template>

          <v-list-item-title class="responsive-title">{{
            item
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Dados da lista
      items: [
        "Inscrição no Formulário de Recrutamento",
        "Envio de Documentação",
        "Aguardar Seleção e Participar de Entrevista",
        "Preparação para Viagem e Início do Trabalho",
      ],
    };
  },
};
</script>

<style scoped>
.responsive-title {
  white-space: normal; /* Permite a quebra de linha automática */
  word-wrap: break-word; /* Quebra as palavras longas se necessário */
}
</style>
