<template>
  <h2 class="text-center mb-6">Benefícios da Oportunidade</h2>
  <div class="card-container">
    <div
      v-for="(item, index) in cards"
      :key="index"
      class="fade-card"
      :style="delay"
    >
      <v-icon color="#f68c25" size="x-large" class="mb-5"
        >mdi-{{ item.icon }}</v-icon
      >
      <h3>{{ item.title }}</h3>
      <p>{{ item.content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: "Crescimento Profissional",
          content:
            "Trabalhe com tecnologias avançadas e aprimore suas habilidades em um ambiente de trabalho europeu.",
          delay: "--delay: 0.0s",
          icon: "finance",
        },
        {
          title: "Suporte Completo",
          content:
            "Cuidamos de tudo para você! Desde a obtenção do visto até a sua chegada na França.",
          delay: "--delay: 0.5s",
          icon: "charity",
        },
        {
          title: "Condições de Trabalho",
          content:
            "Condições salariais competitivas e uma experiência cultural única.",
          delay: "--delay: 1.0s",
          icon: "account-hard-hat",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Estilo do contêiner para centralizar os cards */
.card-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  line-height: 1.2rem;
}

.card-container h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.card-container-icon {
  font-size: 28px;
}

/* Estilo dos cards */
.fade-card {
  opacity: 0;
  transform: translateY(20px); /* Ligeiro deslocamento para baixo */
  animation: fadeIn 0.8s forwards; /* Aplica a animação de fade-in */
  animation-delay: var(--delay); /* Adiciona o atraso com base na variável */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 270px;
}

/* Keyframes para a animação de fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
