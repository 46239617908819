<template>
  <v-card>
    <v-card-text>
      <v-radio-group inline label="Gênero" :rules="[required]">
        <v-radio label="Feminino" value="F"></v-radio>
        <v-radio label="Masculino" value="M"></v-radio>
      </v-radio-group>
      <v-text-field
        v-model="name"
        label="Nome Completo"
        :rules="[required]"
        variant="outlined"
      />
      <v-text-field
        label="Data de Nascimento"
        v-model="birthdate"
        :rules="[isAdult]"
        type="date"
        required
        variant="outlined"
      ></v-text-field>
      <v-autocomplete
        v-model="nation"
        label="Nacionalidade Atual"
        :items="countryNames"
        variant="outlined"
      ></v-autocomplete>
      <v-autocomplete
        v-model="countryBirth"
        label="País de Nascimento"
        :items="countryNames"
        variant="outlined"
      ></v-autocomplete>
      <v-autocomplete
        v-model="civil"
        label="Estado Civil"
        :items="etatcivil"
        variant="outlined"
      ></v-autocomplete>
      <!-- <v-text-field
        v-model="email"
        label="Email"
        :rules="[required, emailRule]"
        variant="outlined"
      /> -->
      <!-- Adicione mais campos conforme necessário -->
    </v-card-text>
  </v-card>
</template>

<script>
import DataCountry from "@/data/countries.json";
export default {
  props: ["name,email"],
  data() {
    return {
      required: (v) => !!v || "Este campo é obrigatório",
      emailRule: (v) => /.+@.+\..+/.test(v) || "Email inválido",
      birthdate: "",
      nation: null,
      countryBirth: null,
      countryNames: [],
      civil: null,
      etatcivil: ["Solteiro", "Casado", "Divorciado", "Viúvo(a)"],
    };
  },
  mounted() {
    this.populateCountryNames();
  },
  methods: {
    populateCountryNames() {
      this.countryNames = DataCountry.map((item) => item.nome_pais);
    },
    isAdult(value) {
      if (!value) return "Data de nascimento é obrigatória.";

      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      const dayDifference = today.getDate() - birthDate.getDate();

      if (
        age > 18 ||
        (age === 18 &&
          (monthDifference > 0 ||
            (monthDifference === 0 && dayDifference >= 0)))
      ) {
        return true;
      }

      return "Você precisa ser maior de idade.";
    },
  },
};
</script>
