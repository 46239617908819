<template>
  <v-container>
    <h1>Formulário de Recrutamento para a França</h1>
    <v-stepper alt-labels v-model="e1">
      <template v-slot:default="{ prev, next }">
        <v-stepper-header>
          <template v-for="(step, index) in steps" :key="index">
            <v-stepper-item
              :complete="e1 > index"
              :step="index"
              :value="step.number"
              :title="step.title"
              :subtitle="step.subtitle"
              editable
            ></v-stepper-item>
            <v-divider v-if="index < steps.length - 1"></v-divider>
          </template>
        </v-stepper-header>

        <v-stepper-window>
          <v-stepper-window-item
            v-for="(step, index) in steps"
            :key="index"
            :value="step.number"
          >
            <component :is="step.component" />
          </v-stepper-window-item>
        </v-stepper-window>

        <v-stepper-actions
          :disabled="disabled"
          @click:next="next"
          @click:prev="prev"
        ></v-stepper-actions>
      </template>
    </v-stepper>
    <!-- <input v-model="name" placeholder="Nome" />
    <input v-model="age" placeholder="Idade" type="number" />
    <button @click="addUser">Adicionar Usuário</button> -->
  </v-container>
</template>

<script>
import axios from "axios";
import FormData from "@/components/FormData.vue";
import FormContact from "@/components/FormContact.vue";

export default {
  components: {
    // FormData,
  },
  name: "FormView",
  title:
    "Recrutamento Cortadores, Desossadores e Abatedores - RM COUPEDESOS BRASIL",
  data() {
    return {
      e1: 1,

      steps: [
        {
          number: 1,
          title: "Informações Pessoais",
          subtitle: "",
          component: FormData,
          data: {},
        },
        {
          number: 2,
          title: "Dados de Contato",
          subtitle: "",
          component: FormContact,
        },
        {
          number: 3,
          title: "Dados de Contato",
          subtitle: "",
        },
      ],
      name: "",
      age: null,
    };
  },
  methods: {
    async addUser() {
      try {
        const response = await axios.post(
          "https://recrutamento---interim-default-rtdb.europe-west1.firebasedatabase.app/recrutamento.json",
          {
            name: this.name,
            age: this.age,
            createdAt: new Date().toISOString(),
          }
        );
        console.log("Dados adicionados:", response.data);
        alert("Usuário adicionado com sucesso!");
        this.name = ""; // Limpa o campo
        this.age = null;
      } catch (error) {
        console.error("Erro ao adicionar usuário: ", error);
      }
    },
  },
  computed: {
    disabled() {
      return this.e1 === 1
        ? "prev"
        : this.e1 === this.steps
        ? "next"
        : undefined;
    },
  },
};
</script>
