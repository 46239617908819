<template>
  <v-container fluid class="hero">
    <v-container>
      <div class="">
        <h1>
          Alcance novas <a class="text-color">oportunidades</a> de carreira na
          <a class="text-color">França</a> com total suporte e segurança
        </h1>
        <p>
          Estamos em busca de profissionais experientes na área de corte e abate
          de suínos para integrar equipes de empresas reconhecidas na França. Se
          você tem as habilidades e deseja uma experiência internacional, essa é
          sua chance!
        </p>
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.hero {
  width: 100%;
  height: 100vh; /* Ocupa toda a altura da janela */
  display: flex;
  align-items: center; /* Centraliza o conteúdo verticalmente */
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)),
    url("@/assets/pig-carcasses-cut-half-stored-refrigerator-room-food-processing-plant.jpg")
      center/cover no-repeat; /* Imagem de fundo */
  color: rgb(255, 255, 255); /* Cor do texto */
  text-align: center; /* Alinhamento do texto */
  padding: 0 20px; /* Padding para espaçamento lateral em telas menores */
  box-sizing: border-box;
}

.hero h1 {
  font-size: 2.8rem; /* Tamanho da fonte do título */
  max-width: 500px; /* Limita a largura do parágrafo */
  margin-bottom: 20px; /* Espaçamento abaixo do título */
  font-weight: 800px;
  line-height: 2.5rem;
  text-align: left;
}
.text-color {
  color: #f68c25;
}

.hero p {
  font-size: 1.2rem; /* Tamanho da fonte do parágrafo */
  max-width: 500px; /* Limita a largura do parágrafo */
  line-height: 1.5; /* Altura da linha para melhor legibilidade */
  text-align: left;
}

.hero .cta-buttons {
  margin-top: 30px; /* Espaçamento acima dos botões */
}

.hero .cta-buttons a {
  display: inline-block;
  padding: 12px 24px; /* Espaçamento interno dos botões */
  margin: 0 10px; /* Espaçamento entre os botões */
  font-size: 1rem; /* Tamanho da fonte dos botões */
  color: white;
  background-color: #007bff; /* Cor de fundo do botão */
  text-decoration: none; /* Remove sublinhado do link */
  border-radius: 5px; /* Arredonda os cantos do botão */
  transition: background-color 0.3s; /* Transição suave ao passar o mouse */
}

.hero .cta-buttons a:hover {
  background-color: #0056b3; /* Cor de fundo do botão ao passar o mouse */
}
</style>
