<template>
  <div>
    <h2 class="text-center mb-6">
      Está pronto para dar um passo importante na sua carreira?
    </h2>
    <p>Inscreva-se agora e junte-se a uma equipe que valoriza o seu talento!</p>
    <div class="button-container">
      <a
        href="https://forms.gle/wqUGG9tY5vrpvhSy9"
        style="text-decoration: none"
        target="blank"
        ><button class="btn bouncing-button" style="" id="customBtn">
          Inscreva-se
        </button></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CTASection",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?Roboto&display=swap");
.button-container {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}
.btn {
  cursor: pointer;
  border: 0px solid rgb(0, 0, 0);
  font-family: "system-ui";
  font-size: 24px;
  color: rgb(255, 255, 255);
  padding: 10px 30px;
  transition: 2230ms;
  width: 257px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 13px;
  background: #f68c25;
  font-weight: 700;
  --hover-borderSize: 0px;
  --hover-bgc: #f68c25;
  --hover-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.blinking-button {
  animation: blink 2s infinite; /* Animação contínua */
}

.bouncing-button {
  animation: bounce 2s infinite; /* Animação contínua */
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0); /* Posição inicial e final */
  }
  50% {
    transform: translateY(-10px) scale(1.05); /* Sobe e aumenta ligeiramente de tamanho */
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1; /* Totalmente visível */
  }
  50% {
    opacity: 0; /* Invisível no meio do ciclo */
  }
}

.btn:hover {
  color: rgb(255, 255, 255);
  width: 337px;
  background: #f68c25 none repeat scroll 0% 0% / auto padding-box border-box;
  border-color: rgb(204, 0, 105);
  border-width: 0px;
  border-style: solid;
  cursor: pointer;
}
</style>
