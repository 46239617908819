<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Estilos da seção */
.landing-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px; /* Padding para evitar que o conteúdo encoste nas bordas em telas menores */
  box-sizing: border-box;
}

/* Estilos do conteúdo */
.landing-content {
  max-width: 980px; /* Define a largura máxima do conteúdo */
}

.landing-content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espaçamento entre as colunas */
  padding: 20px 0;
}

.landing-content-block .landing-image,
.landing-content-block .landing-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo da imagem */
.landing-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Estilos para o texto */
.landing-text {
  max-width: 500px;
  white-space: normal; /* Permite a quebra de linha automática */
  word-wrap: break-word; /* Quebra as palavras longas se necessário */
}

.landing-text h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.landing-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Responsividade para telas maiores: layout lado a lado */
@media (min-width: 768px) {
  .landing-content-block {
    flex-direction: row;
    text-align: left;
  }
}

/* Título */
.landing-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Parágrafo */
.landing-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.5;
}

/* Botão de CTA */
.cta-button {
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  background-color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* Efeito no botão */
.cta-button:hover {
  background-color: #0056b3;
  color: white;
  transform: scale(1.05);
}
</style>
