<template>
  <section
    :style="{ backgroundColor: backgroundColor }"
    class="colored-section"
  >
    <div class="landing-section">
      <div class="landing-content">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ColoredSection",
  props: {
    backgroundColor: {
      type: String,
      default: "#ffffff", // Define uma cor padrão caso não seja especificada
    },
  },
};
</script>

<style scoped>
.colored-section {
  padding: 40px; /* Espaçamento interno */
  color: #333; /* Cor padrão do texto */
}
</style>
