<template>
  <div class="home">
    <HeroSection />
    <SectionsLanding backgroundColor="#f8f9fa">
      <OportunitiesSection />
    </SectionsLanding>

    <SectionsLanding>
      <StepsSection />
    </SectionsLanding>

    <SectionsLanding backgroundColor="#f8f9fa">
      <QualifySection />
    </SectionsLanding>

    <SectionsLanding>
      <CtaSection />
    </SectionsLanding>

    <FooterSection />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/pages/HeroSection.vue";
import OportunitiesSection from "@/pages/OportunitiesSection.vue";
import StepsSection from "@/pages/StepsSection.vue";
import QualifySection from "@/pages/QualifySection.vue";
import SectionsLanding from "@/components/SectionsLanding.vue";
import CtaSection from "@/pages/CtaSection.vue";
import FooterSection from "@/pages/FooterSection.vue";

export default {
  name: "HomeView",
  title:
    "Recrutamento Cortadores, Desossadores e Abatedores - RM COUPEDESOS BRASIL",
  components: {
    HeroSection,
    OportunitiesSection,
    StepsSection,
    QualifySection,
    CtaSection,
    SectionsLanding,
    FooterSection,
  },
};
</script>
