<template>
  <v-footer class="footer">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="4" class="text-center">
          <p>
            &copy; {{ new Date().getFullYear() }} — RM COUPEDESOS BRASIL. Todos
            os direitos reservados. CNPJ: 54.265.337/0001-50
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
  margin-top: 80px;
}

.footer-logo {
  margin: 0 auto; /* Centraliza horizontalmente */
  display: block; /* Certifica que o v-img é tratado como um bloco */
}

p {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #ccc;
}
</style>
