<template>
  <div>
    <h2 class="text-center mb-6">Exigências e Qualificações</h2>
    <p>
      Experiência comprovada em corte, abate e ou desossa é essencial, assim
      como habilidades com facas e atenção à segurança alimentar
    </p>
    <div class="landing-content-block">
      <div class="mx-auto my-8" elevation="0" max-width="450">
        <v-card-title> Faça o download da sua CTPS Digital </v-card-title>
        <v-card-text> e nos envie para comprovar sua experiência. </v-card-text>
        <v-card-actions
          ><a
            href="https://apps.apple.com/br/app/carteira-de-trabalho-digital/id1295257499"
            tabIndex="0"
            ><img
              class="bn46"
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              target="_blank"
              alt="bn45"
          /></a>

          <a
            href="https://play.google.com/store/apps/details?id=br.gov.dataprev.carteiradigital&hl=pt"
            tabIndex="0"
            target="_blank"
            ><img
              class="bn45"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
              alt="bn45"
          /></a>
        </v-card-actions>
      </div>
      <v-img src="@/assets/comment-marche-1b7e08fd11.png"></v-img>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bn46 {
  width: 150px;
  height: 50px;
}
.bn45 {
  width: 170px;
  height: 50px;
}
</style>
